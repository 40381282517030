import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"
import slideAnimation from "utils/slideAnimation"

const SectionHeader = ({ title, content, type }) => {
  return (
    <motion.div
      className={`section-header${type ? ` section-header--${type}` : ""}`}
      {...(type === "center" || !content ? fadeAnimation : {})}
    >
      <div className="row">
        <div className="col-xl-12">
          <motion.h2
            {...(type !== "center" && content ? slideAnimation() : {})}
          >
            {title}
          </motion.h2>
        </div>
        {content && (
          <div
            className={
              type === "center" ? "col-xl-12" : "col-xl-11 offset-xl-1"
            }
          >
            <motion.h2 {...(type !== "center" ? slideAnimation("rtl") : {})}>
              <span>{content}</span>
            </motion.h2>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default SectionHeader
