import { CUBIC_BEZIER } from "utils/constants"

const slideAnimation = (direction = "ltr") => ({
  initial: {
    x: direction === "rtl" ? 15 : -15,
    opacity: 0,
  },
  whileInView: {
    x: 0,
    opacity: 1,
  },
  viewport: {
    once: true,
  },
  transition: {
    type: "tween",
    ease: CUBIC_BEZIER,
    duration: 0.5,
    delay: 0.2,
  },
})

export default slideAnimation
