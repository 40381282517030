import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"
import slideAnimation from "utils/slideAnimation"

const PageHeader = ({ title, content, isDark, marginZero }) => {
  return (
    <section
      className={`page-header${isDark ? " page-header--dark" : ""}${
        marginZero ? " m-0" : ""
      }`}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <motion.h1 {...(content ? slideAnimation() : fadeAnimation)}>
              {title}
            </motion.h1>
          </div>
        </div>
        {content && (
          <div className="col-xl-11 offset-xl-1">
            <motion.h1 {...slideAnimation("rtl")}>
              <span>{content}</span>
            </motion.h1>
          </div>
        )}
      </div>
    </section>
  )
}

export default PageHeader
