import "./styles.scss"

import React, { useState } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

const AnimatedLink = motion(Link)

const Content = ({ pageData }) => {
  const [cat, setCat] = useState(pageData?.acfFaq?.faqContent[0]?.category)

  const links = [
    {
      link: "/regulamin/",
      header: "Regulamin",
      description: "Wszystkie informacje dotyczące zakupów w jednym miejscu.",
    },
    {
      link: "/sposoby-dostawy/",
      header: "Dostawa",
      description: "Wybierz najbardziej optymalną dla Ciebie formę dostawy.",
    },
    {
      link: "/metody-platnosci/",
      header: "Płatności",
      description: "Sprawdź oferowane metody płatności.",
    },
    {
      link: "/zasady-zwrotu-reklamacji/",
      header: "Zwroty i reklamacje",
      description:
        "Zapoznaj się z tymi informacjami przed złożeniem zamówienia.",
    },
  ]

  return (
    <section className="faq-content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <motion.h5 {...fadeAnimation}>
              Znajdziesz tutaj odpowiedzi na najczęściej zadawane pytania
              dotyczące naszych produktów, realizacji projektu i współpracy.
              Naszym celem jest dostarczenie Ci klarownych i wyczerpujących
              informacji, abyś mógł podejmować świadome decyzje. Jeśli
              potrzebujesz jeszcze więcej informacji lub masz dodatkowe pytania,
              skontaktuj się z nami – jesteśmy tu, by Ci pomóc!
            </motion.h5>

            <motion.div className="faq-content__nav" {...fadeAnimation}>
              <h5>Kategorie</h5>
              <div className="faq-content__nav-wrapper">
                {pageData?.acfFaq?.faqContent?.map((item, index) => (
                  <button
                    className={item.category === cat ? "current" : ""}
                    onClick={() => setCat(item.category)}
                    key={index}
                  >
                    {item.category}
                  </button>
                ))}
              </div>
            </motion.div>

            <motion.h3 {...fadeAnimation}>Kategoria - {cat}</motion.h3>
            <div className="faq-content__content">
              {pageData?.acfFaq?.faqContent
                ?.filter(item => item.category === cat)
                .map(item =>
                  item.listing.map((node, index) => (
                    <motion.div
                      className="faq-content__item"
                      key={index}
                      {...fadeAnimation}
                    >
                      <div className="row">
                        <div className="col-md-4">
                          <h5>{node.question}</h5>
                        </div>
                        <div className="col-md-7 offset-md-1">
                          <p
                            dangerouslySetInnerHTML={{ __html: node.answer }}
                          ></p>
                        </div>
                      </div>
                    </motion.div>
                  ))
                )}
            </div>

            <motion.h3 {...fadeAnimation}>Przydatne linki</motion.h3>
            <div className="faq-content__content">
              <div className="row">
                {links?.map((item, index) => (
                  <div className="col-sm-6" key={index}>
                    <AnimatedLink
                      key={index}
                      className="faq-content__link"
                      to={item.link}
                      {...fadeAnimation}
                    >
                      <strong>{item.header}</strong>
                      <p>{item.description}</p>
                    </AnimatedLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
